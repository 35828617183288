<template>
  <div style="display: flex; align-items: center">
    <input
      ref="input"
      :type="inputType"
      :min="minInput"
      :max="maxInput"
      :maxlength="2"
      pattern="[0-9]"
      v-model="model"
      :class="inputClasses"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
    <span v-if="!isLastChild && separator">
      <span v-html="separator"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SingleOtpInput',
  props: {
    value: {
      type: String
    },
    separator: {
      type: String
    },
    focus: {
      type: Boolean
    },
    inputClasses: {
      type: String
    },
    shouldAutoFocus: {
      type: Boolean
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      }
    },
    isLastChild: {
      type: Boolean
    },
    minInput: {
      type: Number,
      default: 0
    },
    maxInput: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      model: this.value || ''
    };
  },
  mounted() {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus();
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },
    // whenever question changes, this function will run
    focus(newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && this.$refs.input && this.focus) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    }
  },
  methods: {
    handleOnChange() {
      let cond = this.maxInput > 9 ? 2 : 1;
      if (this.model.length > cond) {
        this.model = this.model.slice(0, cond);
      }

      if (this.model.length === cond) {
        return this.$emit('on-change', this.model);
      }

      // if(this.model.length < cond){
      //   return this.$emit('on-change', this.model);
      // }
    },
    handleOnKeyDown(event) {
      // Only allow characters 0-9, DEL, Backspace and Pasting
      const keyEvent = event || window.event;
      const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
      if (this.isCodeNumeric(charCode) || charCode === 8 || charCode === 86 || charCode === 46) {
        this.$emit('on-keydown', event);
      } else {
        keyEvent.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105);
    },
    handleOnPaste(event) {
      return this.$emit('on-paste', event);
    },
    handleOnFocus() {
      this.$refs.input.select();
      return this.$emit('on-focus');
    },
    handleOnBlur() {
      let cond = this.maxInput > 9 ? 2 : 1;

      if (this.model.length < cond && this.maxInput > 9) {
        return this.$emit('on-change', this.model ? `0${this.model}` : '');
      }

      return this.$emit('on-blur');
    }
  }
};
</script>
