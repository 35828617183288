<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content
      :ok-disabled="isDisableBtn"
      @ok="handleOke"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Product id  -->
          <b-col cols="6" v-if="!createItem.id">
            <b-form-group id="input-create-filter-product" label="Product " label-for="create-filter-product">
              <b-form-select
                data-vv-as="product id"
                v-validate="{ required: true }"
                name="create-filter-product"
                id="create-filter-product"
                v-model="createItem.product_id"
                :options="allProductFilter"
                :disabled="!!createItem.id"
              >
                <!-- <template v-slot:first>
                  <b-form-select-option disabled :value="null">---Chọn đi---</b-form-select-option>
                </template> -->
              </b-form-select>

              <span v-show="errors.has('create-filter-product')" class="is-danger-text position-relative">{{
                errors.first('create-filter-product')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-else>Product: {{ createItem.product && createItem.product.name }} </b-col>

          <!-- Period id  -->
          <b-col cols="6" v-if="!createItem.id">
            <b-form-group>
              <label for="create_period_no">Period No :</label>
              <b-form-input
                data-vv-as="Period No"
                v-validate="{ required: true }"
                name="create_period_no"
                id="create_period_no"
                v-model="createItem.period_no"
                type="text"
                :class="{ input: true, 'is-danger': errors.has('create_period_no') }"
                :disabled="!!createItem.id"
              ></b-form-input>
              <span v-show="errors.has('create_period_no')" class="is-danger-text position-relative">{{
                errors.first('create_period_no')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-else>Period: #{{ createItem.period_no }} </b-col>

          <!-- Min ticket  -->
          <b-col cols="6" v-if="!createItem.id">
            <b-form-group>
              <label for="create_min_ticket">Min ticket:</label>
              <b-form-input
                :disabled="!!createItem.id"
                id="create_min_ticket"
                v-model="createItem.min_ticket"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-else>Min ticket: {{ createItem.min_ticket }} </b-col>

          <!-- Min percent  -->
          <b-col cols="6" v-if="!createItem.id">
            <b-form-group>
              <label for="create_min_percent">Min percent:</label>
              <b-form-input
                :disabled="!!createItem.id"
                id="create_min_percent"
                v-model="createItem.min_percent"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-else>Min percent: {{ createItem.min_percent }} </b-col>

          <b-col cols="12">
            <div v-for="item in listPriceBuyGroup" :key="item.id">
              <span>{{ item.percent }}</span> - <span>{{ formatPrice(item.price * createItem.min_ticket) }}</span>
            </div>
          </b-col>

          <!-- Status  -->
          <b-col cols="6" v-if="false">
            <b-form-group>
              <label for="create_status">Status :</label>
              <b-form-checkbox
                class="customSwitch"
                switch
                size="lg"
                :checked="!!createItem.status"
                @click.native.prevent="createItem.status = !createItem.status"
                :disabled="!createItem.ticket_amount"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="createItem.id">Status: {{ createItem.status ? true : false }} </b-col>
        </b-row>
        <b-row v-if="createItem.id">
          <b-col cols="12">
            <hr />
            <h4 class="text-center">Nhập thông tin vé</h4>
          </b-col>
          <b-col cols="12">
            <div class="font-15 mb-2" v-for="(ticket, index) in ticketListBuyGroup" :key="`ticket_${index}`">
              <hr />
              <div class="font-15 font-weight-bold">
                Vé {{ index + 1
                }}<span class="float-right" v-if="!ticket.id && index === ticketListBuyGroup.length - 1">
                  <v-icon class="text-danger border-1" small @click="handleRemoveTicket(index)">flaticon-delete</v-icon>
                  <!-- <v-icon :id="'view_' + item.item.id" class="text-info" @click="handleEdit(item.item)">mdi-eye</v-icon> -->
                </span>
              </div>
              <div v-if="ticket.id">
                <img :src="ticket.image_url" style="height: 50px" @click="openGallery('lightbox_' + ticket.id)" />
                <LightBox
                  :ref="'lightbox_' + ticket.id"
                  :media="[{ src: ticket.image_url }]"
                  :show-light-box="false"
                  :show-thumbs="false"
                ></LightBox>

                <div v-for="(numbers, indexNumber) in ticket.ticket_info" :key="`ticket_info_${indexNumber}`">
                  <div class="d-flex align-items-center">
                    <strong class="d-inline-block mr-2">{{ numbers.title }}</strong>
                      <!-- <div>
                        {{ numbers.list_number && numbers.list_number.join() }}
                      </div> -->
                    <div v-if="createItem.game.handler == 'max3dpro'">
                      <div 
                        v-for="(item, count) in new Array(numbers.list_number.length / 3).fill(0)"
                        :key="'group_'+count"
                        class="d-flex flex-wrap pt-2"
                      >
                        <NumberItem
                          v-for="(char, idx) in numbers.list_number.slice(count * 3, count * 3 + 3)"
                          :key="'numberp' + index + '_' + char + '_' + idx"
                          :number="+char"
                          :isCustomForResult="false"
                          class="spacingNumber"
                          :isActive="true"
                          :isSM="true"
                        />
                        <br />
                      </div>
                    </div>
                    <div v-else class="d-flex flex-wrap pt-2">
                      <NumberItem
                        v-for="(char, idx) in numbers.list_number"
                        :key="'numberp' + index + '_' + char + '_' + idx"
                        :number="+char"
                        :isCustomForResult="false"
                        class="spacingNumber"
                        :isActive="true"
                        :isSM="true"
                      />
                    </div>
                  </div>
                </div>
                <!-- ticketMapping[] -->
              </div>
              <div v-else>
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      id="input-img-url"
                      label="Image URL:"
                      label-for="img-url"
                      aria-describedby="input-image-help"
                    >
                      <b-form-input
                        id="img-url"
                        v-model="ticket.image_url"
                        type="text"
                        placeholder="Image URL"
                      ></b-form-input>
                      <b-form-text id="input-image-help">Enter exist image link or upload new image file.</b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
                      <b-form-file
                        v-model="ticket.image_file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div
                  class="mb-1"
                  v-for="(numbers, indexNumber) in ticket.ticket_info"
                  :key="`ticket_info_${indexNumber}`"
                >
                  <div class="d-flex align-items-center">
                    <strong class="d-inline-block mr-2">{{ numbers.title || ticketMapping[indexNumber] }}</strong>
                    <div>
                      <OtpInput
                        :ref="`ticket_${index}_${indexNumber}`"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="createItem.product && createItem.product.slot_count"
                        :should-auto-focus="false"
                        :is-input-num="true"
                        @on-change="handleOnChange($event, index, indexNumber)"
                        @on-complete="handleOnComplete($event, index, indexNumber)"
                        :minInput="isMax3dPro ? 0 : 1"
                        :maxInput="isMax3dPro ? 9 : createItem.game.handler === 'power' ? 55 : 45"
                        :groupCount="isMax3dPro ? 3 : createItem.product.slot_count"
                      />
                    </div>

                    <div class="ml-1" v-if="listError.includes(`${index}_${indexNumber}`)">
                      <v-icon :id="`error_${index}_${indexNumber}`" class="text-danger"> flaticon-warning</v-icon>
                      <b-tooltip :target="`error_${index}_${indexNumber}`" triggers="hover"
                        >Bộ số nhập chưa hợp lệ. Kiểm tra lại</b-tooltip
                      >
                    </div>
                  </div>
                </div>
                <div v-if="ticket.ticket_info && ticket.ticket_info.length < 6">
                  <b-button variant="secondary" class="d-flex align-items-center" @click="addMoreTicketNumber(index)">
                    Thêm bộ số
                  </b-button>
                </div>
              </div>
            </div>
            <div v-if="!createItem.lottery_result && !createItem.finish_percent" class="d-flex justify-content-center">
              <b-button variant="primary" class="d-flex align-items-center" @click="addMoreTicket()">
                Thêm vé
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="8">
        <h4 class="b-card-title">Buy Group List</h4>
      </b-col>
      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right px-5" @click="addItem">Add</b-button>
      </b-col>

      <!-- Filter Game -->
      <b-col cols="3">
        <b-form-group id="input-screen-filter-game" label="Game" label-for="screen-filter-game">
          <b-form-select
            id="screen-filter-game"
            v-model="filter_game"
            :options="allGameFilter"
            @change="onFilterChange"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Filter Product -->
      <b-col cols="3">
        <b-form-group id="input-screen-filter-product" label="Product" label-for="screen-filter-product">
          <b-form-select
            id="screen-filter-product"
            v-model="filter_product"
            :options="allProductFilter"
            @change="onFilterChange"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- filter date from -->
      <!-- <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_from_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col> -->
      <!-- filter date to -->
      <!-- <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_to_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col> -->

      <!-- Filter Active -->
      <!-- <b-col cols="3">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col> -->
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:head()="data">
        <span class="text-info">{{ data.field.text }}</span>
      </template>

      <template v-slot:cell(reward_amount)="{ value }">{{ formatPrice(value) }}</template>
      <template v-slot:cell(actual_amount)="{ value }">{{ formatPrice(value) }}</template>
      <template v-slot:cell(lottery_time)="{ value }">{{ formatTime(value) }}</template>
      <template v-slot:cell(finish_percent)="{ value }">{{ (value || 0) + '%' }}</template>
      <template v-slot:cell(ticket_amount)="{ value, item }">
        <span
          :class="{
            'text-danger font-weight-bold': value < item.min_ticket
          }"
        >
          {{ value || 0 }}
        </span>
        /
        <span>
          {{ item.min_ticket || 0 }}
        </span></template
      >

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 2" variant="secondary">Đã hủy</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 1" variant="success">Mở bán</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && new Date() - new Date(item.item.lottery_time) > 0">
          Đã hủy.
        </b-badge>
        <b-badge
          :id="'btn_sold_' + item.item.id"
          class="mr-1"
          :class="{
            pointer: item.item.ticket_amount >= item.item.min_ticket
          }"
          v-else-if="item.value === 0"
          variant="dark"
          @click="activeItem(item.item)"
          >Chưa mở bán
          <v-icon x-small class="text-success ml-2" v-if="item.item.ticket_amount >= item.item.min_ticket"
            >flaticon2-check-mark</v-icon
          >
        </b-badge>
        <b-badge class="mr-1" v-else variant="warning"> ???</b-badge>

        <b-tooltip
          v-if="item.item.ticket_amount >= item.item.min_ticket"
          :target="'btn_sold_' + item.item.id"
          triggers="hover"
          >Mở bán</b-tooltip
        >
      </template>

      <template v-slot:cell(result_status)="item">
        <div v-if="item.item.status === 1">
          <b-badge class="mr-1" v-if="!!item.item.lottery_result" variant="success">Đã có kết quả</b-badge>
          <b-badge class="mr-1" v-else-if="new Date() - new Date(item.item.lottery_time) > 0" variant="warning"
            >Chờ kết quả</b-badge
          >
        </div>
      </template>

      <template v-slot:cell(returned_reward)="item">
        <div v-if="item.item.lottery_result">
          <b-badge class="mr-1" v-if="item.value === 1" variant="success">Đã trả thưởng</b-badge>
          <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.actual_amount > 0" variant="warning"
            >Chờ trả thưởng</b-badge
          >
          <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.status === 1" variant="dark"
            >Không trúng thưởng</b-badge
          >
          <b-badge class="mr-1" v-else-if="item.value === 0" variant="dark"></b-badge>
          <b-badge class="mr-1" v-else variant="dark"> ???</b-badge>
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:cell(actions)="item">
        <div class="d-flex align-items-center justify-content-center">
          <div v-if="checkInTimeAction(item.item, 'fill')" class="mr-1">
            <v-icon :id="'fill_' + item.item.id" class="text-success" @click="confirmFill(item.item)"
              >flaticon2-box</v-icon
            >
            <b-tooltip :target="'fill_' + item.item.id" triggers="hover">Mua vé</b-tooltip>
          </div>

          <div v-if="item.item.actual_amount > 0 && item.item.returned_reward === 0" class="mr-1">
            <v-icon :id="'gift_' + item.item.id" class="text-success" @click="confirmReward(item.item)"
              >flaticon2-gift</v-icon
            >
            <b-tooltip :target="'gift_' + item.item.id" triggers="hover">Trả thưởng</b-tooltip>
          </div>
          <div v-if="checkInTimeAction(item.item, 'cancel')">
            <v-icon :id="'delete_' + item.item.id" class="text-danger" @click="deleteItem(item.item)"
              >mdi-delete</v-icon
            >
            <b-tooltip :target="'delete_' + item.item.id" triggers="hover">Hủy vé</b-tooltip>
          </div>
          <div>
            <v-icon :id="'view_' + item.item.id" class="text-info" @click="handleEdit(item.item)">mdi-eye</v-icon>
            <b-tooltip :target="'view_' + item.item.id" triggers="hover">Xem</b-tooltip>
          </div>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline id="input-per-page" style="margin-right: 20px; margin-bottom: 0">
          <label for="per-page" class="mr-sm-2">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
// import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import OtpInput from './components/OtpInput.vue';
import NumberItem from '../game_result/components/NumberItem.vue';
import LightBox from 'vue-image-lightbox';
import { convertArrayToObject } from '@/utils/function';

// import { formatDateForView } from '@/utils/time.js';

export default {
  name: 'Withdraw Transaction',
  components: {
    OtpInput,
    NumberItem,
    LightBox
    // datePicker,
  },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_game: null,
      filter_product: null,
      filter_from_date: '',
      filter_to_date: '',
      statusList: [
        { value: 1, text: 'Success' },
        { value: 2, text: 'Rejected' },
        { value: 0, text: 'Pending' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Game', key: 'game.name' },
        { text: 'Product', key: 'product.name' },
        { text: 'Lottery time', key: 'lottery_time' },
        { text: 'Period no', key: 'period_no' },
        { text: 'Finish percent', key: 'finish_percent' },
        { text: 'Amount / Min (Ticket)', key: 'ticket_amount' },
        { text: 'Reward amount', key: 'reward_amount' },
        { text: 'Actual amount', key: 'actual_amount' },
        { text: 'Return reward', key: 'returned_reward' },
        { text: 'Result status', key: 'result_status', class: 'text-center' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allGameFilter: [],
      allStockConfigData: null,
      allProductFilter: [],
      allProductData: [],
      selectedPayment: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      createItem: {
        id: '',
        product_id: null,
        min_percent: 1,
        min_ticket: 1,
        period_no: '',
        status: false
      },
      ticketListBuyGroup: [],

      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      file_upload: null,
      ticketMapping: ['A', 'B', 'C', 'D', 'E', 'F'],
      listError: []
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    createItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.checkDataUpdate();
      }
    }
  },
  computed: {
    isDisableBtn() {
      return this.errors.has('Status');
    },

    role() {
      return this.$store.getters.currentUser.data.role;
    },
    allGameData() {
      return convertArrayToObject(this.allGameFilter, 'value');
    },
    listPriceBuyGroup() {
      const productId = this.createItem?.product_id;
      if (!productId || !this.allStockConfigData) return [];
      const stockConfigData = this.allStockConfigData?.[productId];

      return stockConfigData;
    },
    isMax3dPro() {
      return this.createItem.game && this.createItem.game.handler === 'max3dpro';
    }
  },
  created() {
    this.fetchInitData();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buy Group' }, { title: 'Buy Group List' }]);
  },
  methods: {
    async fetchInitData() {
      try {
        const allGame = ApiService.get('allgame');
        const allProduct = ApiService.get('allproduct');
        const allStockConfig = ApiService.get('stocks-config');
        const [allGameRes, allProductRes, allStockConfigRes] = await Promise.all([allGame, allProduct, allStockConfig]);

        const showList = ['power', 'mega', 'max3dpro'];
        const showListProduct = [
          'power5',
          'power7',
          'power8',
          'power9',
          'power10',
          'power11',
          'power12',
          'power13',
          'power14',
          'power15',
          'power18',
          'mega5',
          'mega7',
          'mega8',
          'mega9',
          'mega10',
          'mega11',
          'mega12',
          'mega13',
          'mega14',
          'mega15',
          'mega18',
          'max3dprocombine3',
          'max3dprocombine6',
          'max3dprocombine9',
          'max3dprocombine18',
          'max3dprocombine36',
          'max3dprocombo6',
          'max3dprocombo12',
          'max3dprocombo20',
          'max3dprocombo30',
          'max3dprocombo42',
          'max3dprocombo56',
          'max3dprocombo72',
          'max3dprocombo90',
        ];
        let allProductDataFormat = JSON.parse(JSON.stringify(allProductRes.data.data));
        allProductDataFormat = allProductDataFormat.map((item) => ({
          ...item,
          text: item.name
        }));

        this.allStockConfigData = allStockConfigRes?.data?.data;

        this.allGameFilter = allGameRes.data.data.filter((item) => showList.includes(item.handler));
        this.allProductFilter = allProductDataFormat.filter((item) => showListProduct.includes(item.handler));
        this.allProductData = JSON.parse(JSON.stringify(allProductDataFormat));
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      }
    },
    onFilterChange: _.debounce(function () {
      this.fetchData();
    }, 500),
    async fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let page = this.currentPage;
      ApiService.setHeader();
      await ApiService.query('buy-group', {
        params: {
          limit,
          page,
          game_id: this.filter_game || undefined,
          product_id: this.filter_product || undefined,
          status: 1
        }
      })
        .then((res) => {
          this.items = res.data?.data?.data;
          this.rows = res.data?.data?.total;

          this.isBusy = false;
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    handleEdit: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.createItem = {
        ...item
      };
      this.modalTitle = `View buy group #${item.id}`;
    },

    addItem: function () {
      this.dialog = true;
      this.modalTitle = `Create buy group`;
    },

    close() {
      this.dialog = false;

      this.createItem = {
        id: '',
        product_id: null,
        min_percent: 1,
        min_ticket: 1,
        period_no: '',
        status: false
      };
      this.ticketListBuyGroup = [];
      this.listError = [];
    },

    handleOke(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.createItem.id) {
        this.updateBuyGroup();
      } else {
        this.createBuyGroup();
      }
    },

    async createBuyGroup() {
      let valid = await this.$validator.validate();
      if (!valid) {
        return;
      }

      const { product_id, min_percent, min_ticket, period_no, status } = this.createItem;
      const findPriceBuyGroup = this.listPriceBuyGroup.find((item) => +item.percent === +min_percent);
      if (!findPriceBuyGroup) {
        Swal.fire({
          title: 'Giá trị min percent không hợp lệ',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });

        return;
      }

      if (findPriceBuyGroup.price * this.createItem.min_ticket < 10000) {
        Swal.fire({
          title: 'Giá trị min percent tương ứng phải ít nhất 10.000đ',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });

        return;
      }

      let formData = new FormData();
      formData.append('product_id', product_id);
      formData.append('min_percent', min_percent);
      formData.append('min_ticket', min_ticket);
      formData.append('period_no', period_no);
      formData.append('status', status ? 1 : 0);

      axios({
        method: 'POST',
        url: '/buy-group',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(async (res) => {
          if (res?.data?.status) {
            await this.fetchData();
            this.close();

            this.$bvToast.toast(`Thêm nhóm thành công`, {
              title: null,
              variant: 'success',
              autoHideDelay: 2000,
              noCloseButton: true
            });

            // this.handleEdit(res?.data?.data);
          }
        })
        .catch((errors) => {
          console.error(errors);
          const message = errors?.response?.data?.data?.message || errors;

          Swal.fire({
            title: `${message}`,
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000
          });

          // this.$bvToast.toast(`${message}`, {
          //   title: null,
          //   variant: 'danger',
          //   autoHideDelay: 3000,
          //   noCloseButton: true
          // });
        });
    },
    factorial(n) {
      let r = 1;
      while (n > 1) r *= n--;
      return r;
    },
    countPermutations(n, r) {
      return this.factorial(n) / this.factorial(n - r);
    },
    createPermutations(xs) {
      const ret = [];
      for (let i = 0; i < xs.length; i += 1) {
        const rest = this.createPermutations(xs.slice(0, i).concat(xs.slice(i + 1)));
        if (!rest.length) {
          ret.push([xs[i]]);
        } else {
          for (let j = 0; j < rest.length; j += 1) {
            ret.push([xs[i]].concat(rest[j]));
          }
        }
      }
      return ret;
    },
    createUniqueArray(arr) {
      return [...new Set(arr.map((a) => a.join("")))].map((a) => a.split(""));
    },
    isMax3DProComboValid(numbers, productCount) {
      if (!numbers || numbers.length < 9 || numbers.length % 3 != 0) {
        return false;
      }
      const arrayNumber = _.chunk(numbers, 3).map((arr) => arr.join(""));

      return this.countPermutations(arrayNumber.length, 2) === productCount;
    },
    isMax3DProCombineValid(numbers, productCount) {
      if (!numbers || numbers.length != 6) {
        return false;
      }

      const arrayNumber = _.chunk(numbers, 3);

      const firstNumberPermutations = this.createUniqueArray(this.createPermutations(arrayNumber[0]));
      const secondNumberPermutations = this.createUniqueArray(this.createPermutations(arrayNumber[1]));
      const permutationCount = firstNumberPermutations.length * secondNumberPermutations.length;
      // ignore case numbers has same all 6 digit
      return permutationCount > 1 && permutationCount === productCount;
    },
    validateListNumber(listNumber, key) {
      const listErrorUnique = new Set([...this.listError]);
      let isValid = true;

      if (listNumber.length !== this.createItem?.product?.slot_count) {
        listErrorUnique.add(key);
        isValid = false;
      }

      const gameHandler = this.createItem?.game?.handler;
      let maxNumber = 9;
      let minNumber = 0;
      let listNumberCount = [...listNumber];
      if (gameHandler == "max3dpro") {
        // Validate max3d pro
        let productHandler = this.createItem?.product?.handler;
        const productCount = parseInt(productHandler.replace("max3dprocombo", "").replace("max3dprocombine", ""));
        if (
          (productHandler.includes("combo") && !this.isMax3DProComboValid(listNumber, productCount)) ||
          (productHandler.includes("combine") && !this.isMax3DProCombineValid(listNumber, productCount))
        ) {
            listErrorUnique.add(key);
            isValid = false;
        }
      } else {
        maxNumber = gameHandler === 'power' ? 55 : 45;
        minNumber = 1;
        listNumberCount = new Set([...listNumber]);
      }
      if ([...listNumberCount].length !== this.createItem?.product?.slot_count) {
        listErrorUnique.add(key);
        isValid = false;
      }

      if (listNumber.some((item) => item > maxNumber || item < minNumber)) {
        listErrorUnique.add(key);
        isValid = false;
      }

      if (isValid) {
        listErrorUnique.delete(key);
      }

      this.listError = [...listErrorUnique];

      return isValid;
    },
    async updateBuyGroup() {
      let bodyData = [];
      let isError = false;
      this.ticketListBuyGroup.forEach((item, indexTicket) => {
        let listTicket = [];

        item.ticket_info.forEach((itemInfo, indexinfo) => {
          const newData = {
            title: this.ticketMapping[indexinfo],
            list_number: itemInfo?.list_number?.map((n) => +n) || []
          };

          let validateValue = this.validateListNumber(newData.list_number, `${indexTicket}_${indexinfo}`);

          if (!validateValue) {
            isError = true;
          }

          listTicket.push(newData);
        });

        bodyData.push({
          id: item.id,
          image_file: item.image_file,
          image_url: item.image_url,
          listTicket
        });
      });

      if (bodyData.some((item) => !item.image_file && !item.image_url)) {
        Swal.fire({
          title: 'Cập nhật đẩy đủ hình cho tất cả các vé trong nhóm.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        isError = true;
      }

      if (isError) {
        return;
      }
      try {
        const listRequest = [];
        for await (const item of bodyData) {
          if (item.id) {
            continue;
          }

          let formData = new FormData();
          const { id } = this.createItem;
          formData.append('buy_group_id', id);
          formData.append('ticket_info', JSON.stringify(item.listTicket));
          if (item.image_file) {
            formData.append('image', item.image_file);
          } else if (item.image_url) {
            formData.append('image_url', item.image_url);
          }
          formData.append('status', 1);
          listRequest.push(
            axios({
              method: 'POST',
              url: '/buy-group-ticket',
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            })
          );
        }

        await Promise.all(listRequest);

        await this.fetchData();

        this.$bvToast.toast(`Thêm thông tin vé thành công`, {
          title: null,
          variant: 'success',
          autoHideDelay: 2000,
          noCloseButton: true
        });
        this.close();
      } catch (errors) {
        console.error(errors);
        const message = errors?.response?.data?.data?.message || errors;
        this.$bvToast.toast(`${message}`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Bạn chắc chắn muốn hủy vé ?',
        html: `<div><strong>#${item.id}</strong> - <strong>${item.game?.name}</strong> - <strong>${item.product?.name}</strong> - <strong>Kỳ ${item.period_no}</strong></div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#34bfa3',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          this.actionRequest('cancel', item.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Hủy vé thành công!',
              showConfirmButton: false,
              timer: 3000
            });
          });

          // ApiService.put('withdraw-transactions/' + item.id, { status: 1 }).then(() => {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Payment Approved',
          //     showConfirmButton: false,
          //     timer: 3000
          //   }).then(() => {
          //     this.getWithdrawData();
          //   });
          // });
        }
      });
    },
    activeItem(item) {
      if (item.ticket_amount < item.min_ticket) {
        return;
      }

      Swal.fire({
        title: 'Bạn chắc chắn muốn đăng bán vé ?',
        html: `<div><strong>#${item.id}</strong> - <strong>${item.game?.name}</strong> - <strong>${item.product?.name}</strong> - <strong>Kỳ ${item.period_no}</strong></div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#34bfa3',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          ApiService.patch('/buy-group/' + item.id, { status: 1 }).then(() => {
            this.fetchData();
            Swal.fire({
              icon: 'success',
              title: 'Đăng bán thành công',
              showConfirmButton: false,
              timer: 3000
            });
          });
        }
      });
    },
    confirmReward(item) {
      Swal.fire({
        title: 'Bạn chắc chắn muốn trả thưởng',
        html: `<div><strong>#${item.id}</strong> - <strong>${item.game?.name}</strong> - <strong>${item.product?.name}</strong> - <strong>Kỳ ${item.period_no}</strong></div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#34bfa3',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          this.actionRequest('reward', item.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Trả thưởng thành công!',
              showConfirmButton: false,
              timer: 3000
            });
          });
          // ApiService.put('withdraw-transactions/' + item.id, { status: 1 }).then(() => {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Payment Approved',
          //     showConfirmButton: false,
          //     timer: 3000
          //   }).then(() => {
          //     this.getWithdrawData();
          //   });
          // });
        }
      });
    },
    confirmFill(item) {
      Swal.fire({
        title: 'Bạn chắc chắn muốn mua phần còn lại của vé mua chung này?',
        html: `<div><strong>#${item.id}</strong> - <strong>${item.game?.name}</strong> - <strong>${item.product?.name}</strong> - <strong>Kỳ ${item.period_no}</strong></div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#34bfa3',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          this.actionRequest('fill', item.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Mua vé thành thành công!',
              showConfirmButton: false,
              timer: 3000
            });
          });
        }
      });
    },
    async checkDataUpdate() {
      try {
        if (this.createItem?.id) {
          const listTicketRes = await ApiService.query(`/buy-group/${this.createItem?.id}/tickets`).then(
            (res) => res.data
          );
          if (listTicketRes?.status) {
            this.ticketListBuyGroup = listTicketRes.data;
          }
        }
      } catch (error) {
        console.error('checkDataUpdate', error);
      }
    },
    addMoreTicket() {
      this.ticketListBuyGroup.push({
        ticket_info: [
          {
            list_number: []
          }
        ]
      });
    },
    addMoreTicketNumber(ticketIndex) {
      this.$set(this.ticketListBuyGroup, ticketIndex, {
        ...this.ticketListBuyGroup[ticketIndex],
        ticket_info: [
          ...this.ticketListBuyGroup[ticketIndex].ticket_info,
          {
            // title: this.ticketMapping[this.ticketListBuyGroup[ticketIndex].ticket_info.length],
            list_number: []
          }
        ]
      });
    },
    handleOnComplete() {
      // console.log('handleOnComplete', value);
      // this.editedItem.ticket_info = value;
    },
    handleOnChange(value, ticket_index, number_index) {
      const listNumber = value.split('');
      const gameHandler = this.createItem.game.handler;
      if (listNumber.length > 0) {
        this.ticketListBuyGroup[ticket_index].ticket_info[number_index].list_number = _.chunk(listNumber, gameHandler == "max3dpro" ? 1 : 2).map(
          (item) => item.join('')
        );
      }
      // this.editedItem.ticket_info = value;
    },
    openGallery(key) {
      try {
        this.$refs[`${key}`]?.[0].showImage(0);
      } catch (error) {
        console.error(error);
      }
    },
    async actionRequest(action, id, params = {}) {
      // axios({
      //         method: 'POST',
      //         url: '/buy-group-ticket',
      //         data: formData,
      //         headers: { 'Content-Type': 'multipart/form-data' }
      //       })

      return await ApiService.post(`/buy-group/${id}/${action}`, params, { 'Content-Type': 'application/json' });
    },
    checkInTimeAction(
      item
      // , action
    ) {
      // item
      try {
        const { game, lottery_time, finish_percent, status } = item;
        let metaData = this.allGameData?.[game.id].metadata;
        metaData = JSON.parse(metaData);
        const lotteryTime = new Date(lottery_time);
        const currentTime = new Date();
        // console.log(lotteryTime.getTime(), metaData.group_count_down * 1000, currentTime.getTime());

        // if(action === 'fill' && finish_percent == 100){
        //   return false;
        // }

        // if(action === 'delete' && finish_percent == 100){
        //   return false;
        // }

        return (
          lotteryTime - currentTime > 0 &&
          lotteryTime - currentTime < metaData.group_count_down * 1000 &&
          finish_percent !== 100 &&
          status === 1
        );
      } catch (error) {
        return false;
      }
    },
    handleRemoveTicket(index) {
      const cloneData = JSON.parse(JSON.stringify(this.ticketListBuyGroup));
      cloneData.splice(index, 1);
      this.$set(this, 'ticketListBuyGroup', cloneData);
    }
  }
};
</script>
